<template>
  <!-- this from use in role and client table -->
  <div>
    <validation-observer ref="clientObserver">
      <b-sidebar
        id="addclient"
        ref="clientForm"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
      >
        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} Client
        </h3>
        <div class="line user-underline" />
        <!--Client Name start-->
        <b-form-group
          label="Client Name"
          label-for="clientname"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required|alpha_spaces|min:3|max:40"
          >
            <b-form-input
              id="name"
              v-model="name"
              :state="errors.length > 0 ? false : null"
              placeholder="Client Name"
              name="clientname"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Client Name end-->
        <!--Country Name start-->

        <b-form-group
          label="Country"
          label-for="country name"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Country"
            rules="required"
          >
            <v-select
              id="country name"
              v-model="country"
              hide-details
              name="country"
              label="name"
              :options="countries"
              :reduce="(country) => country.id"
              class="select-size-lg"
              placeholder="Select Country"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Country name End-->

        <template #footer>
          <!--form footer start-->
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Save" }}
              <!-- v-if="btnLoader" -->
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData"
            >
              Clear
            </button>
          </div>
          <!--form footer end-->
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eventBus } from '@/main'
import VSelect from 'vue-select'

export default {
  name: 'ClientTableForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
  },
  data() {
    return {
      id: null,
      name: null,
      btnLoader: false,
      country: null,
      countries: [],
    }
  },
  mounted() {
    eventBus.$on('edit-client', data => {
      if (data) {
        this.id = data.id
        this.name = data.name
        this.country = data.country_id
        this.$root.$emit('bv::toggle::collapse', 'addclient')
      }
    })
    this.getCountryData()
  },
  destroyed() {
    eventBus.$off('edit-client')
  },
  methods: {
    /**
     * clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.name = null
      this.country = null
      this.$refs.clientObserver.reset()
    },

    /**
     * check the form validation
     * @returns if success true then call saveClientDtl Method
     */
    validationForm() {
      this.$refs.clientObserver.validate().then(success => {
        if (success) {
          this.saveClientDtl()
        }
      })
    },
    /**
     * Get Countries data
     * @returns Countries (variable)
     */
    async getCountryData() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/client/countries-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.countries = data && data.countries ? data.countries : []
      }
    },

    /**
     * Add & Update the clientTableForm details & call client listing in clienttable
     * @emits clientList
     * @returns client detail add & update
     */
    async saveClientDtl() {
      this.btnLoader = true
      let input = {
        name: this.name,
        country_id: this.country,
      }
      let response = null
      if (this.id) {
        response = await this.getHTTPPutResponse(
          `admin/master/client/update/${this.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'admin/master/client/create',
          input,
          true,
        )
      }
      if (response && response.status === 200) {
        input = {
          page: 1,
        }
        eventBus.$emit('clientList', input)
        this.$root.$emit('bv::toggle::collapse', 'addclient')
        setTimeout(() => {
          this.btnLoader = false
        }, 200)
      }
      this.btnLoader = true
    },

    /**
     *  Clear form details
     */
    clearData() {
      this.name = null
      this.country = null
      this.$refs.clientObserver.reset()
    },
  },
}
</script>
